import * as React from 'react';

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from 'gatsby';
import { Post } from '../components/post';

interface Data
{
  site:{
    siteMetadata:{
      title:string
    }
  },
  allMarkdownRemark: {
    edges: {
      node: {
        html: string
        excerpt: string
        frontmatter: {
            title?: string
            date: string
            description: string
            tags:string[]
        }
        fields: {
            slug: string
            html: string
        }
      }
    }[]
  }
}

interface PageContext
{
  tag:string;
  slug:string;
}

const TagTemplate = ({ data, pageContext, location }:{data:Data, pageContext:PageContext, location:string})=>
{
  const posts = data.allMarkdownRemark.edges;
  const t = `All posts tagged with ${pageContext.tag}`
  return (
  <Layout location={location} title={data.site.siteMetadata.title}>
    <SEO title={t} />
    {t}
    <hr/>

    {posts.map(({ node }, index) => {
        let lower = node.frontmatter.tags.map(s=>s.toLowerCase());
        if (lower.indexOf(pageContext.tag) != -1) {
          const title = node.frontmatter.title || node.fields.slug;
          return (
            <Post key={index} node={node}/>
          )
        }
      })}

  </Layout>)
}


export default TagTemplate;

export const TagQuery = graphql`
query TagQuery {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        excerpt
        html
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          tags
        }
      }
    }
  }
}`